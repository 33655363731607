
import { Component, Vue } from 'vue-property-decorator'
import ImportData from '@/components/importTemplateData/Index.vue'
import TypeDialog from '@/components/typeDialog/Index.vue'
import { Page, TypeInfo } from '../../types/common'
import { PageType } from '../../types/plant'
import { KeepAlive } from '@/utils/decorators'

@Component({
  name: 'plantList',
  components: { ImportData, TypeDialog }
})
@KeepAlive
export default class extends Vue {
  private plantTypeList: TypeInfo[]= []
  private searchInfo = {
    plantName: '',
    plantType: ''
  }

  private tableData: PageType[] = []
  private loading = false

  private total = 0
  private page = 1
  private size = 10
  private isShowImport = false
  private typeShow = false

  created () {
    this.getTypeList()
    this.getData()
  }

  getData () {
    this.loading = true
    this.$axios.get<Page<PageType>>(this.$apis.plant.plantPage, {
      ...this.searchInfo,
      page: this.page,
      size: this.size
    }).then((res) => {
      this.tableData = res.list || []
      this.total = res.total || 0
    }).finally(() => {
      this.loading = false
    })
  }

  getTypeList () {
    this.$axios.get<{total: number; list: TypeInfo[]}>(this.$apis.common.thinktankTypeList, {
      dicType: 'plantType'
    }).then((res) => {
      this.plantTypeList = res.list || []
    })
  }

  onSearch () {
    this.page = 1
    this.getData()
  }

  // 查看
  onDetail (id: string) {
    this.$router.push({
      name: 'plantDetail',
      params: { id }
    })
  }

  onAdd () {
    this.$router.push({
      name: 'plantAdd'
    })
  }

  onUpdate (id: string) {
    this.$router.push({
      name: 'plantUpdate',
      params: { id }
    })
  }

  onDelete (id: string) {
    this.$confirm('确认删除吗, 是否继续?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      this.$axios.post(this.$apis.plant.plantDelete, {
        plantId: id
      }).then(() => {
        this.$message.success('删除成功')
        this.page = 1
        this.getData()
      })
    })
  }

  closePlantDlg () {
    this.typeShow = false
    this.getTypeList()
  }
}
