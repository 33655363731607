
import { Component, Vue, Prop } from 'vue-property-decorator'
import { TypeInfo } from '@/types/common'

@Component
export default class TypeDialog extends Vue {
  @Prop() readonly typeShow!: boolean
  @Prop() readonly dicType!: string
  private tableData: TypeInfo[] = []
  private loading = false
  private oldData: { [key: number]: string } = {}
  private isEdit = false

  created () {
    this.getTypeList()
  }

  get typeTitle () {
    let title = ''
    switch (this.dicType) {
      case 'plantType':
        title = '植物类别'
        break
      case 'pestsType':
        title = '虫害类别'
        break
      case 'medicineType':
        title = '药剂类别'
        break
    }
    return title
  }

  closeDialog () {
    this.$emit('update:typeShow', false)
    this.$emit('closeDialog')
  }

  getTypeList () {
    this.loading = true
    this.oldData = {}
    this.$axios.get<{ total: number; list: TypeInfo[] }>(this.$apis.common.thinktankTypeList, {
      dicType: this.dicType
    }).then((res) => {
      this.tableData = res.list || []
    }).finally(() => {
      this.loading = false
    })
  }

  // 新增
  onAdd () {
    if (!this.isEdit) {
      this.tableData.push({ dicValue: '', isInput: true })
      this.$nextTick(() => {
        // 滚动到表格最底部
        ;(this.$refs.table as any).bodyWrapper.scrollTop = (this.$refs.table as any).bodyWrapper.scrollHeight
      })
      this.isEdit = true
    } else {
      this.$message.warning('请先保存正在编辑的类别')
    }
  }

  // 取消
  onCancel (row: TypeInfo, index: number) {
    if (row.dicCode) {
      // 是否为编辑
      this.tableData[index].isInput = false
      // 取消值不变
      this.tableData[index].dicValue = this.oldData[index]
    } else {
      this.tableData.splice(index, 1)
    }
    this.isEdit = false
  }

  // 保存
  onSave (row: TypeInfo) {
    if (row.dicValue) {
      let info: object = { dicValue: row.dicValue, dicType: this.dicType }
      let url = this.$apis.common.insertThinktankDic
      if (row.dicId) {
        info = { ...info, dicId: row.dicId }
        url = this.$apis.common.updateThinktankDic
      }
      this.$axios.post(url, info).then(() => {
        this.isEdit = false
        this.$message.success('保存成功')
        this.getTypeList()
      })
    } else {
      this.$message.warning('请输入' + this.typeTitle)
    }
  }

  // 编辑
  onEdit (row: TypeInfo, index: number) {
    if (!this.isEdit) {
      this.tableData[index] = { ...row, isInput: true }
      this.oldData[index] = row.dicValue
      this.$set(this.tableData, index, this.tableData[index]) // 重新渲染表格数据
      this.isEdit = true
    } else {
      this.$message.warning('请先保存正在编辑的' + this.typeTitle)
    }
  }

  // 删除
  onDelete (dicId: string) {
    if (!this.isEdit) {
      this.$confirm('确认删除吗, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$axios.post(this.$apis.common.deleteThinktankDic, { dicId }).then(() => {
          this.$message.success('删除成功')
          this.getTypeList()
        })
      })
    } else {
      this.$message.warning('请先保存正在编辑的' + this.typeTitle)
    }
  }
}
