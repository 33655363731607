import { createDecorator } from 'vue-class-component'
import { RawLocation, Route } from 'vue-router'
import store from '../store'

export const KeepAlive = createDecorator((options) => {
  options.beforeRouteLeave = function (to: Route, from: Route, next: (to?: false | RawLocation | ((vm: Vue) => void) | undefined) => void) {
    if (!to.path.includes(from.path)) {
      store.commit('removeKeepAlive', options.name)
    }
    next()
  }

  options.beforeRouteEnter = function (to: Route, from: Route, next: (to?: false | RawLocation | ((vm: Vue) => void) | undefined) => void) {
    store.commit('addKeepAlive', options.name)
    next()
  }

  options.activated = function () {
    options.created && options.created.call(this)
  }
})
